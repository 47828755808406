import * as React from 'react'

import { Containers as AppointmentContainers } from '@grandvisionhq/appointments'
import { NextPage, withErrorPage } from '@grandvisionhq/next'
import { useIntl } from '@grandvisionhq/state'
import { compose } from '@grandvisionhq/utils'
import { InitialProps, Page, Props } from '@grandvisionhq/www-next/pages/store-detail'

import { withCms, withLayout } from '../../cms'
import { defaultErrorProps } from '../../components/error-props'
import { withServerStoreDataContext } from '../../components/hocs/with-store-code-context'

import '@grandvisionhq/appointments/dist/index.css'

const StoreDetailsPage: NextPage<Props, InitialProps> = ({ storeData, ...props }) => {
  const { getLabel } = useIntl()
  const { DropInWidget } = AppointmentContainers

  const pageTitle =
    getLabel('stores.page.singularTitle') !== ''
      ? `${getLabel('stores.page.singularTitle')} ${storeData?.shortName}`
      : `${storeData?.shortName}`

  return (
    <Page
      {...props}
      storeData={storeData}
      pageTitle={pageTitle}
      dropInAppointmentBookingWidget={DropInWidget}
    />
  )
}

StoreDetailsPage.getInitialProps = Page.getInitialProps

export default compose<Props, Props>(
  withServerStoreDataContext(),
  withCms(),
  withErrorPage(defaultErrorProps),
  withLayout()
)(StoreDetailsPage)
